@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

// :root {
// 	// --bs-blue: #0d6efd;
// 	// --bs-indigo: #6610f2;
// 	// --bs-purple: #6f42c1;
// 	// --bs-pink: #d63384;
// 	// --bs-red: #dc3545;
// 	// --bs-orange: #fd7e14;
// 	// --bs-yellow: #ffc107;
// 	// --bs-green: #198754;
// 	// --bs-teal: #20c997;
// 	// --bs-cyan: #0dcaf0;
// 	// --bs-white: #fff;
// 	// --bs-gray: #6c757d;
// 	// --bs-gray-dark: #343a40;
// 	// --bs-primary: #0d6efd;
// 	// --bs-secondary: #6c757d;
// 	// --bs-success: #198754;
// 	// --bs-info: #0dcaf0;
// 	// --bs-warning: #ffc107;
// 	// --bs-danger: #dc3545;
// 	// --bs-light: #f8f9fa;
// 	// --bs-dark: #212529;
// 	--bs-font-sans-serif: $font-family-sans-serif;
// 	--bs-font-monospace: $font-family-monospace;
// 	--bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
// }

// $blue:    #548fe7;
// $indigo:  #6610f2;
// $purple:  #8d60e2;
// $pink:    #d63384;
// $red:     #b9313f;
// $orange:  #fd7e14;
// $yellow:  #fac935;
// $green:   #198754;
// $teal:    #3accc5;
// $cyan:    #50d4ee;

// $gray-100: 		#f8f9fa;
// $gray-300:		#bdbdbd;
// $gray-900: 		#495057;

// // First override some or all individual color variables
// $primary:       $yellow;
// $secondary:     $gray-300;
// $success:       $teal;
// $info:          $cyan;
// $warning:       $yellow;
// $danger:        $red;
// $light:         $gray-100;
// $dark:          $gray-900;

// $theme-colors: (
//   primary: $primary,
//   secondary: $secondary,
//   success: $success,
//   info: $info,
//   warning: $warning,
//   danger: $danger,
//   light: $light,
//   dark: $dark,
// );

// $theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");

// Custom Variables and Overrides
@import 'uplift-variables.scss'; // Ensure this file only contains variable overrides and does not produce CSS output


// Import remaining Bootstrap and custom styles
@import "../node_modules/bootstrap/scss/bootstrap"; // This will load all of Bootstrap if needed



$font-family: $font-family-sans-serif;

.form-control{
	color: $gray-600 !important;
	border:3px solid $primary !important;
	font-family: $font-family-sans-serif;
	border-radius: 0px;
	&:hover {
		border:3px solid $cyan !important;
	}	
}
.btn{
	border-radius: 0px;
}


/* home page styles */

#main-options-container, #edit-nav {
	padding-bottom: 80px;	
}
.icon-toggle-button{
	height: auto;
}

// #messages {
// 	padding-top: 10px;
// 	// height: 99vh;
// 	// overflow-y: auto;
// 	display: flex;
// 	flex-direction: column-reverse;
// }
// #show-messages {
// 	display: none;
// 	border-top: 1px solid $primary;
// 	text-align: center;
// 	padding-top: 5px;
// 	color: $primary;
// 	z-index: 1038;
// }
// @media (max-width: 576px) {
// 	#messages{
// 		background-color: white;
// 		height: 400px;
// 		overflow-y: auto;
// 	}
// 	#show-messages {
// 		display: block;
// 	}
// 	// fade messages on small screens
// 	#messages:before {
// 		content:'';
// 		width:100%;
// 		height:40px;
// 		position:absolute;
// 		left:0;
// 		top: -3px;
// 		background:linear-gradient(white 10px, transparent);
// 		z-index: 1037;
// 	  }	
// }

// #main-input-container {
// 	// just above other fixed navs
// 	z-index: 1035;  
// 	position: fixed;
// 	bottom: 4px;
// 	right: 4px;
// }

#account-button-container, #main-submit-button, #show-messages {
	svg {
		height: 2em;
		width: 2em;
		fill: $primary;
	}
}

// #main-input {

// }

// #main-submit-button{
	
// }

.edit-link{
	border: 3px solid transparent;
	padding: 1rem !important;
}

/* page & user styles  */
$img-large: 10em !default;
$img-large-margin: 2em auto 1em auto !default;
$img-large-mobile: 9em !default;
$img-large-margin-mobile: 2.5em auto 1.5em auto !default;
$img-small: 8em !default;
$img-small-mobile: 7em !default;
$img-small-margin: 3em auto 3em auto !default;
$img-small-margin-mobile: 4em auto 3em auto !default;

.page-container{
	min-height: 100vh;
}
.page-items-container {
	min-width: 300px;
	max-width: 576px;
	text-align: center;
	padding-bottom: 20px;	
	margin-right: auto;
	margin-left: auto;	
	align-items: center;
    vertical-align: middle;
}
.page-prof-container{
	overflow: hidden;	
	min-height: 10em;
	text-align: center;
}

.page-prof{
	// align-items: center;	
	min-height: 50px;
	transition: border 0.6s ease-in-out,opacity 0.8s ease-in-out,max-width 0.8s ease-in-out,height 0.8s ease-in-out,border-radius 0.8s ease-in-out,margin 1s ease-in-out;
}
.page-prof{
	&.square, &.round, &.undefined, &.circle {
		margin: $img-large-margin;
		max-width: $img-large;
	}
}

@include media-breakpoint-down(sm) {
	.page-prof {
		&.square, &.round, &.undefined, &.circle {
			margin: $img-large-margin-mobile;
			max-width: $img-large;
		}
	}
	
}
.page-prof{
	&.small-square {
		margin: $img-small-margin;
		max-width: $img-small;
	}
}

@include media-breakpoint-down(sm) {
	.page-prof{
		&.small-square {
			max-width: 3rem;
			margin: $img-small-margin-mobile;
			max-width: $img-small-mobile;
		}
	}
}

.full {
	max-width: 360px;
	margin: auto auto 2em auto;
}
@include media-breakpoint-down(sm) {
	.page-prof{
		&.full {
			margin: auto auto 2em auto;
			max-width: 300px;
		}
	}
}
@include media-breakpoint-down(sm) {
	.page-prof{
		&.full {
			margin: auto auto 2em auto;
			max-width: 100%;
		}
	}
}

.square{
	border-radius: 0px;
}
.round, .undefined {
	border-radius: 1em;
}
.circle{
	border-radius: 50%;
}
.small-square{
	border-radius: 0px;
}

/* edit page */
#edit-page-container {
	margin-bottom: 60px;
	margin-top: 15px;
	overflow-x: hidden;
	min-height: 50vh;
}

.page-preview{
	border-radius: 1rem;
	background-clip: border-box;
    border: 4px solid rgba(0, 0, 0, 0.125);
    border-radius: 1re;
}

//Highlight text 
.highlight::before {  
	transform: scaleX(0);
	transform-origin: bottom right;
}
  
.highlight:hover::before {
	transform: scaleX(1);
	transform-origin: bottom left;
}
  
.highlight::before {
	cursor: context-menu;
	content: " ";
	display: block;
	position: absolute;
	top: 0; right: 0; bottom: 0; left: 0;
	inset: 0 0 0 0;
	background: $gray-700;
	opacity: .3;
	z-index: 1;
	transition: transform .3s ease;
}
  
.highlight {
	position: relative;
}


.edit-page-item{
	transition: border 0.6s ease-in-out,opacity 0.8s ease-in-out,max-width 0.8s ease-in-out,height 0.8s ease-in-out,border-radius 0.8s ease-in-out;	
}

.edit-page-item:hover {
	opacity: .8;
	border: 3px solid $primary;
}

.page-icon {
	padding: 4px;
	width: 3em;
	height: auto;
}
@include media-breakpoint-down(sm) {
	.page-icon{
		padding: 2px;
		width: 2em;
	}
}


/* color */ 
.color-picker{
	height: 40px;
}
.white {
	color: white;
	.page-icon {
		filter: invert(100%);
	}
}
.black {
	color: black;
}
a{
	color: inherit;
}

/* navs */
.page-nav{
	background-color:rgba(255, 255, 255, 0.9);
}
.page-nav-holder {
	min-height: 4.5em;
}
@include media-breakpoint-down(sm) {
	.page-nav-holder{
		min-height: 4em;
	}
}

.loading {
	animation: rotateL 12s infinite linear;
	transform: rotate(15deg);
}
.flash {
	animation: flash 1.8s infinite linear;
	transform: opacity 1.8s linear;
}

.page-logo {
	width: 100%;
	img {
		width: 3.5em;
	}
}

@include media-breakpoint-down(sm) {
	.page-logo img {
		width: 3em;
	}	
}

/* Theme style options  */
#theme-select {
	padding: $container-padding-x;
}
#theme-select > .btn {
	margin-bottom: 1rem;
	border:3px solid transparent;
	transition: 1s ease-out;
}
.btn-check:checked + .btn, .btn-check:active + .btn, .btn:active, .btn.active, .edit-page-item.active {
	border:3px solid $gray-600 !important;
	box-shadow: 0 0 5px 5px $primary !important;
	transform: translateX(1px) translateY(1px);
}

.page-buttons {
	padding: 0px 15px;
}
.page-button-container{
    -webkit-user-select: none;
    user-select: none;
	text-decoration: none !important;
}
.page-button{
	transition: transform .6s ease-out, border .6s ease-out, background-color .8s ease-in-out, color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .5s ease-in-out !important;

	border:inherit;
	
	margin-bottom: 1rem;
    display: block !important;
    width: 100% !important;
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;

    text-align: center;
    vertical-align: middle;
	line-height: 1.5;
    -webkit-user-select: none;
    user-select: none;
	text-decoration: none !important;
}

.page-button:hover {
	text-decoration: none !important;
}


.uplift {
	font-family: $font-family-sans-serif;
	.page-button{
		font-family: $font-family;
	}
}

.undefined{
	font-family: $font-family-sans-serif;
}
.undefined .page-button, .btn-undefined {
	border-radius: .5rem;
	border:3px inset transparent;
	background-color: inherit;
	color: inherit;

	@extend .btn-primary;
}


.traditional{
	font-family: Raleway, $font-family-monospace;
}
.traditional .page-button, .btn-traditional {
	font-family: Raleway, $font-family-monospace !important;	
	background-color: inherit;
	border:3px solid transparent;
	font-family: $font-family;
	&:hover {
		color: white !important;
		background-color: $black !important;
	}
	&:active {
		background-color: $gray-900 !important;
	}	
}

.minimalist{
	font-family: Raleway, "Helvetica Neue", Arial;	
}
.minimalist .page-button, .btn-minimalist {
	background-color: transparent;
	border:3px solid inherit;
	font-family: Raleway, "Helvetica Neue", Arial;
	border-radius: 0px;
	&:hover {
		color: white !important;
		background-color: $gray-900 !important;
	}
	&:active {
		box-shadow: 1px 1px white, 2px 2px white !important;
		transform: translateX(1px) translateY(1px);
	}
}

.modern {
	font-family: Roboto, "Helvetica Neue", Arial;
	// .page-icon {
	// 	border-radius: .4em;
	// }
}
.modern .page-button, .btn-modern {
	font-family: Roboto, "Helvetica Neue", Arial;
	background-color: transparent;
	border:3px solid inherit;
	border-radius: 5em !important;
	&:hover {
		transform: translateY(-5px) scale(1.02);
		box-shadow: 0 10px 10px #11323f;
	}
}


.retro {
	font-family: $font-family-monospace;
    font-weight: 400;
}

.retro .page-button, .btn-retro {
	font-family: $font-family-monospace !important;
	background-color: transparent;
	border:3px solid inherit;	
    text-align: left;
    border-radius: 0;
    box-shadow: 4px 4px black, 6px 7px white;
	&:hover {
		box-shadow: 5px 5px $gray-700, 7px 8px white;
		transform: translateX(-2px) translateY(-1px);
	}
	&:active {
		box-shadow: 1px 1px white, 2px 2px white !important;
		transform: translateX(1px) translateY(1px);
	}

	font-family: $font-family-sans-serif;
	.page-button{
		font-family: $font-family;
	}
}



.artistic{
	font-family: Kurale, $font-family-sans-serif;
}
.artistic .page-button, .btn-artistic {
	background-color: transparent !important;
	border:3px solid transparent !important;
	font-family: 'Dancing Script', cursive !important;	
	font-size: 1.5em;
	text-decoration: none;
	
	&:hover {
		text-decoration: underline;
	}
}


.square-btn {
	border-radius: 0px;
}
.round-btn {
	border-radius: 60px;
}



/* Styles for the sun logo */
.sun-logo{
	position: absolute;
	bottom: 2em;
	left: 35px;
	margin: -15px 0 0 -15px;
	width: 40px;
	height: 40px;
}
.sun-logo *, .sun-logo *:before, .sun-logo *:after{
	position: absolute;
}

.sun-logo {
	.rings{
		left: 15px;
		bottom: 5px;
		z-index: 1;
	}
	
	.rings div:before, .rings div:after{
		content: "";
		z-index: 2;
		top: 0;
		left: 0;
		height: inherit;
		width: inherit;
		background: inherit;
	}
	.rings div:before{
		transform: rotate(60deg);
	}
	.rings div:after{
		transform: rotate(-60deg);
	}
	.rings.rotate div:nth-child(even){
		animation: rotateR 12s infinite linear;
	}
	.rings.rotate div:nth-child(odd){
		animation: rotateL 12s infinite linear;
		transform: rotate(15deg);
	}
	.rings div:nth-child(1){
		animation-duration: 45s;
		background: rgba(255, 230, 84, 0.5);
		width: 20px;
		height: 20px;
		margin: -10px 0 0 -10px;
	}
	.rings div:nth-child(2){
		animation-duration: 49s;
		background: rgba(255, 185, 6, 0.5);
		width: 19px;
		height: 19px;
		margin: -9.5px 0 0 -9.5px;
	}
	.rings div:nth-child(3){
		animation-duration: 56s;
		background: rgba(255, 130, 5, 0.5);
		width: 18px;
		height: 18px;
		margin: -9px 0 0 -9px;
	}
	.rings idiv:nth-child(4){
		animation-duration: 93s;
		background: rgba(255, 90, 0, 0.5);
		width: 17px;
		height: 17px;
		margin: -8.5px 0 0 -8.5px;
	}	
}

/* code for the large sun  */
.sun{
	position: absolute;
	margin: -150px 0 0 -150px;
	width: 300px;
	height: 300px;
}
.sun *, .sun *:before, .sun *:after{
	position: absolute;
}

.sun {
	.rings{
		top: 50%;
		left: 50%;
		z-index: 1;
	}
	.rings div:before, .rings div:after{
		content: "";
		z-index: 2;
		top: 0;
		left: 0;
		height: inherit;
		width: inherit;
		background: inherit;
	}
	.rings div:before{
		transform: rotate(60deg);
	}
	.rings div:after{
		transform: rotate(-60deg);
	}
	.rings.rotate div:nth-child(even){
		animation: rotateR 12s infinite linear;
	}
	.rings.rotate div:nth-child(odd){
		animation: rotateL 12s infinite linear;
		transform: rotate(15deg);
	}
	.rings div:nth-child(1){
		animation-duration: 45s;
		background: rgba(255, 230, 84, 0.5);
		width: 200px;
		height: 200px;
		margin: -100px 0 0 -100px;
	}
	.rings div:nth-child(2){
		animation-duration: 49s;
		background: rgba(255, 185, 6, 0.5);
		width: 190px;
		height: 190px;
		margin: -95px 0 0 -95px;
	}
	.rings div:nth-child(3){
		animation-duration: 56s;
		background: rgba(255, 130, 5, 0.5);
		width: 180px;
		height: 180px;
		margin: -90px 0 0 -90px;
	}
	.rings idiv:nth-child(4){
		animation-duration: 93s;
		background: rgba(255, 90, 0, 0.5);
		width: 170px;
		height: 170px;
		margin: -85px 0 0 -85px;
	}
}


/* animations */
$flash-opacity: .5;
@-webkit-keyframes flash {
	0% {
	  opacity: 1;
	}
	50% {
		opacity: $flash-opacity;
	}
	100% {
		opacity: 1;
	}
}
@-moz-keyframes flash {
	0% {
	  opacity: 1;
	}
	50% {
		opacity: $flash-opacity;
	}
	100% {
		opacity: 1;
	}
}
@-ms-keyframes flash {
	0% {
	  opacity: 1;
	}
	50% {
		opacity: $flash-opacity;
	}
	100% {
		opacity: 1;
	}
}
@-o-keyframes flash {
	0% {
	  opacity: 1;
	}
	50% {
		opacity: $flash-opacity;
	}
	100% {
		opacity: 1;
	}
}
@keyframes flash {
	0% {
	  opacity: 1;
	}
	50% {
		opacity: $flash-opacity;
	}
	100% {
		opacity: 1;
	}
}
@-webkit-keyframes flash {
	0% {
	  opacity: 1;
	}
	50% {
		opacity: $flash-opacity;
	}
	100% {
		opacity: 1;
	}
}
@-moz-keyframes flash {
	0% {
	  opacity: 1;
	}
	50% {
		opacity: $flash-opacity;
	}
	100% {
		opacity: 1;
	}
}
@-ms-keyframes flash {
	0% {
	  opacity: 1;
	}
	50% {
		opacity: $flash-opacity;
	}
	100% {
		opacity: 1;
	}
}
@-o-keyframes flash {
	0% {
	  opacity: 1;
	}
	50% {
		opacity: $flash-opacity;
	}
	100% {
		opacity: 1;
	}
}
@keyframes flash {
	0% {
	  opacity: 1;
	}
	50% {
		opacity: $flash-opacity;
	}
	100% {
		opacity: 1;
	}
}


@-webkit-keyframes rotateR{
	from{
		-webkit-transform: rotate(0deg);
	}
	to{
		-webkit-transform: rotate(360deg);
	}
}
@-moz-keyframes rotateR{
	from{
		-moz-transform: rotate(0deg);
	}
	to{
		-moz-transform: rotate(360deg);
	}
}
@-ms-keyframes rotateR{
	from{
		-ms-transform: rotate(0deg);
	}
	to{
		-ms-transform: rotate(360deg);
	}
}
@-o-keyframes rotateR{
	from{
		-o-transform: rotate(0deg);
	}
	to{
		-o-transform: rotate(360deg);
	}
}
@keyframes rotateR{
	from{
		transform: rotate(0deg);
	}
	to{
		transform: rotate(360deg);
	}
}
@-webkit-keyframes rotateL{
	from{
		-webkit-transform: rotate(360deg);
	}
	to{
		-webkit-transform: rotate(0deg);
	}
}
@-moz-keyframes rotateL{
	from{
		-moz-transform: rotate(360deg);
	}
	to{
		-webkit-transform: rotate(0deg);
	}
}
@-ms-keyframes rotateL{
	from{
		-ms-transform: rotate(360deg);
	}
	to{
		-ms-transform: rotate(0deg);
	}
}
@-o-keyframes rotateL{
	from{
		-o-transform: rotate(360deg);
	}
	to{
		-o-transform: rotate(0deg)
	}
}
@keyframes rotateL{
	from{
		transform: rotate(360deg);
	}
	to{
		transform: rotate(0deg);
	}
}


.icon-btn {
	width: 50px;
	height: 50px;
	border: 1px solid $light;
	background: white;
	border-radius: 25px;
	overflow: hidden;
	position: relative;
	transition: width 0.2s ease-in-out;
  }
  .add-btn:hover {
	width: 120px;
  }
  .add-btn::before,
  .add-btn::after {
	transition: width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
	content: "";
	position: absolute;
	height: 4px;
	width: 10px;
	top: calc(50% - 2px);
	background: $primary;
  }
  .add-btn::after {
	right: 14px;
	overflow: hidden;
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
  }
  .add-btn::before {
	left: 14px;
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
  }
  .icon-btn:focus {
	outline: none;
  }
  .btn-txt {
	opacity: 0;
	transition: opacity 0.2s;
  }
  .add-btn:hover::before,
  .add-btn:hover::after {
	width: 4px;
	border-radius: 2px;
  }
  .add-btn:hover .btn-txt {
	opacity: 1;
  }
  .add-icon::after,
  .add-icon::before {
	transition: all 0.2s ease-in-out;
	content: "";
	position: absolute;
	height: 20px;
	width: 2px;
	top: calc(50% - 10px);
	background: $primary;
	overflow: hidden;
  }
  .add-icon::before {
	left: 22px;
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
  }
  .add-icon::after {
	right: 22px;
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
  }
  .add-btn:hover .add-icon::before {
	left: 15px;
	height: 4px;
	top: calc(50% - 2px);
  }
  .add-btn:hover .add-icon::after {
	right: 15px;
	height: 4px;
	top: calc(50% - 2px);
  }
  




.page-demo-container{
	.profile-holder {
		margin: auto;
		color: gray;
		width: 40px;
		height: 40px;
	}
	.page-demo{
		min-width: 180px;
		min-height: 420px;
		max-width: 250px;
		max-height: 500px;
		margin: auto auto auto auto;
		padding: 50px 30px;
		.btn {
			margin-bottom: 10px;
			display: block;
			color: white;
		}
		h2 {
			color: $light;
		}
	}
	.sun {
		top: 0px;
		z-index: 0;
		position: absolute;
		right: -150px;
		margin: -150px 0 0 -150px;	
	}
}
.plain-form {
	box-shadow: inset 0 1px 1px transparent !important;
	border: 1px solid transparent !important;
}

.welcome-container {
	overflow: hidden;


	body {
	  margin:0;
	}
	
	h1 {
	  font-family: 'Lato', sans-serif;
	  font-weight:300;
	  letter-spacing: 2px;
	  font-size:48px;
	}
	p {
	  font-family: 'Lato', sans-serif;
	  letter-spacing: 1px;
	  font-size:14px;
	  color: #333333;
	}
	
	.header {
	  position:relative;
	  text-align:center;
	  background: linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%);
	  color:white;
	}
	
	.inner-header {
	  height:100px;
	  width:100%;
	  margin: 0;
	  padding: 0;
	}
	
	.waves {
	  position:relative;
	  width: 100%;
	  height:15vh;
	  margin-bottom:-7px; /*Fix for safari gap*/
	  min-height:100px;
	  max-height:150px;
	}
	
	.content {
	  position:relative;
	  height:20vh;
	  text-align:center;
	  background-color: white;
	}
	
	/* Animation */
	
	.parallax > use {
	  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
	}
	.parallax > use:nth-child(1) {
	  animation-delay: -2s;
	  animation-duration: 7s;
	}
	.parallax > use:nth-child(2) {
	  animation-delay: -3s;
	  animation-duration: 10s;
	}
	.parallax > use:nth-child(3) {
	  animation-delay: -4s;
	  animation-duration: 13s;
	}
	.parallax > use:nth-child(4) {
	  animation-delay: -5s;
	  animation-duration: 20s;
	}
	@keyframes move-forever {
	  0% {
	   transform: translate3d(-90px,0,0);
	  }
	  100% { 
		transform: translate3d(85px,0,0);
	  }
	}
	/*Shrinking for mobile*/
	@media (max-width: 768px) {
	  .waves {
		height:20px;
		min-height:40px;
	  }
	  .content {
		height:20vh;
	  }
	  h1 {
		font-size:24px;
	  }
	}

}



